import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Customers from '../components/Customers';
import Section, { ColumnsSection, SectionColumn } from '../components/Section';
import LinkButton from '../components/LinkButton';
import { MainTitle, SectionTitle }  from '../components/Titles';
import Activity from '../components/Activity';
import MainImageSection from '../components/MainImageSection';

import { colors } from '../shared/styles';

const CustomerTypesSection = styled(ColumnsSection)`
  margin-bottom : 8rem;
`;


const CitationSection = styled(ColumnsSection)`
  margin-top : 8rem;
  margin-bottom : 8rem;
`;

const Column = styled(SectionColumn)`
  font-size : 2.1rem;
  text-align : justify;
  line-height : 4rem;
`;

const CitationSource = styled.div`
  font-family : TellMeMore;
  text-align : right;
  margin-top : 4rem;
  font-size : 3rem;
`;
//Nous travaillons avec differents types d'acteurs

/*
<MainTitle
  strings={[
    "Des approches qui combinent la rencontre physique et la discussion online"
  ]}
  />
*/
const Page = () => (
  <Layout>
    <SEO title="Clients" />
    <MainImageSection image={require('../images/clients_image.jpg')}>
      <MainTitle
        strings={[
          "Nous realisons des missions sur mesure pour nos clients"
        ]}
        />
    </MainImageSection>
    <SectionTitle>
      Nous travaillons avec trois types d'acteurs
    </SectionTitle>
    <CustomerTypesSection>
      <Column>
        <strong>● Des « grands comptes »</strong> dans le domaine de la dermo-cosmétique, de l’assurance ou encore des services pour qui nous réalisons des missions sur-mesure
      </Column>
      <Column>
        <strong>● Des agences de communication et de conseil en innovation ou expérience client</strong> à qui nous apportons une expertise « consumer insights »
      </Column>
      <Column>
        <strong>● Des start-ups</strong> pour lesquelles nous avons monté une offre « crash test » sur mesure afin d’identifier le potentiel d’une offre, d’un produit ou d’un service auprès d’un public cible.
      </Column>
    </CustomerTypesSection>
    <Customers />
    <CitationSection>
      <Column>
        <em>« Nous avons collaboré avec Tell Me More pour un forum en ligne autour du programme de fidélité de botanic®. Je n'avais encore jamais testé cette méthodologie. Grande découverte. Je suis très satisfaite de l'accompagnement de Delphine sur ce sujet si cher à mon cœur. Du début à la fin, nous avons pu profiter de son expertise. Le résultat est très satisfaisant, nous avons pu identifier des points d'étonnements et des points d'améliorations sur notre programme de fidélité. Je recommande d'une part, cette méthodologie inclusive de forum en ligne, et d'autre part, je recommande les compétences humaines et marketing de Delphine ! À suivre ! »</em>
        <CitationSource>
          Elodie Perillat Fortenville<br/>Responsable relation client<br/>Botanic
        </CitationSource>
        <div style={{marginTop : '8rem'}}/>
        <em>« Nous avons travaillé avec Tell Me More au sujet de la pratique du ski nordique. Le timing et le budget étaient très serrés mais Delphine a su nous délivrer les insights attendus avec ces contraintes importantes. C’est quelqu’un de confiance, qui a un bon réseau de partenaires, une expérience des études quali de plusieurs années et qui fait de son mieux pour répondre aux problématiques de ses clients. A bientôt pour de nouveaux projets ! »</em>
        <CitationSource>
          Marion Bortoletti<br/>Consumer Research Manager<br/>Salomon
        </CitationSource>
      </Column>
      <Column>
        <em>« J’ai eu l’occasion de collaborer avec Tell Me more a plusieurs reprises sur différents sujets pour le secteur la dermo-cosmétique à l’international (France, US, Chine, Mexique, Espagne..)<br/>
        Cela a été un réel plaisir de travailler avec Delphine sur ces différents projets. Elle est à l’écoute de ses clients, positive et n’hésite pas à proposer de nouvelles méthodologies pour répondre au mieux aux problématiques de ses clients. Ses partenaires seniors sont gage de qualité et de confiance pour mener à bien les différentes missions. Ces expériences ont été très positives : je la recommande sans hésiter ! »</em>
        <CitationSource>
          Marie Guillerm<br/>Responsable Business Intelligence et Etudes<br/>Laboratoires Expanscience
        </CitationSource>
      </Column>
    </CitationSection>
  </Layout>
)

export default Page
