import React from "react"
import styled from "styled-components"


const Container = styled.div`
  display : inline-flex;
  flex-direction : column;
  align-items : center;
  padding : 3rem;
`

const Image = styled.img`
  height : 100px;
`;

const Title = styled.div`
  font-weight : bold;
  text-transform : uppercase;
  font-size : 2.1rem;
  letter-spacing : 0.3rem;
  text-align : center;
  margin-top : 3rem;
  line-height : 4rem;
`;

const Content = styled.div`
  font-size : 2.1rem;
  text-align : justify;
  margin-top : 3rem;
`;

const Activity = ({children, image, title, ...props}) =>
  <Container {...props}>
    <Image src={image}/>
    <Title>{title}</Title>
    <Content>{children}</Content>
  </Container>;

export default Activity;
